import { CTALink, Image } from '@finn/ui-components';
import { cn, getCloudinaryImgUrl, parseToHtml } from '@finn/ui-utils';
import Head from 'next/head';

import { StepperItemCosmicMetadata } from '~/types/UIModules';

const StepperItem = ({
  item,
  isManagementCard,
}: {
  item: StepperItemCosmicMetadata;
  isManagementCard?: boolean;
}) => {
  return (
    <>
      <Head>
        {item.icon?.url && (
          <link rel="preload" href={item.icon.url} as="image" />
        )}
      </Head>
      <div>
        {item.icon?.url && (
          <Image
            className="mb-2 h-8 w-8 sm:mb-4"
            src={item.icon?.url}
            alt={item.title}
          />
        )}
        {item.image?.url && (
          <div className="max-w-screen sm:max-w-auto relative left-1/2 right-1/2 -mx-[50vw] mb-2 w-screen overflow-hidden pt-[60%] sm:left-0 sm:right-0 sm:mx-0 sm:mb-4 sm:w-full">
            <Image
              variant="responsive"
              className="absolute left-1/2 top-1/2 min-h-full w-full -translate-x-1/2 -translate-y-1/2"
              smImage={getCloudinaryImgUrl(item.image?.url, { w: 375 })}
              mdImage={getCloudinaryImgUrl(item.image?.url, { w: 404 })} // duplicated large as we not follow design yet
              lgImage={getCloudinaryImgUrl(item.image?.url, { w: 404 })}
              alt={item.title}
            />
          </div>
        )}
        {item.subtitle && (
          <h6 className="global-t6-semibold mb-4">
            {parseToHtml(item.subtitle)}
          </h6>
        )}
        <div>
          <h3 className="global-t5-semibold mb-4">{parseToHtml(item.title)}</h3>
          <p
            className={cn('body-16-light mb-2 pb-1', {
              'mb-4 line-clamp-3 max-h-24': !isManagementCard,
            })}
          >
            {parseToHtml(item.description)}
          </p>
        </div>
        {item.link_href && (
          <CTALink
            className="mt-4"
            href={item.link_href}
            text={item.link_text}
          />
        )}
      </div>
    </>
  );
};

export default StepperItem;
